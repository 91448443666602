import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { getUser, renewTokens } from './user';

const UserSelectorContext = React.createContext(null);

const getTokenExpDate = (token) => {
  if (token && token.split) {
    const encodedPayload = token.split('.')[1];
    const { exp } = JSON.parse(atob(encodedPayload));

    return exp * 1000;
  }

  return null;
};

const getJwtToken = async (tokens) => {
  try {
    const newTokenResponse = await renewTokens(tokens.refreshToken);

    if (newTokenResponse.success) {
      return newTokenResponse.data;
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const UserSelectorContextProvider = ({ children }) => {
  const [jwtTokens, setJwtTokens, clearJwtTokens] = useLocalStorage('jwt-tokens');
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (jwtTokens) {
      axios.defaults.headers.common['Authorization'] = jwtTokens['accessToken'];
      axios.defaults.headers.common['IdToken'] = jwtTokens['idToken'];

      const tokenExpiration = getTokenExpDate(jwtTokens.accessToken);

      if (tokenExpiration <= +new Date()) {
        getJwtToken(jwtTokens).then((newJwtTokens) => {
          setJwtTokens(newJwtTokens);

          axios.defaults.headers.common['Authorization'] = newJwtTokens['accessToken'];
          axios.defaults.headers.common['IdToken'] = newJwtTokens['idToken'];
        });
      } else if (!user) {
        getUser().then((data) => {
          setUser(data);
          setIsLoading(false);
          setIsLoggedIn(true);
        });
      }
    } else {
      setIsLoading(false);
    }
  }, [jwtTokens, setJwtTokens, user]);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          if (jwtTokens) {
            return getJwtToken(jwtTokens).then((newJwtTokens) => {
              axios.defaults.headers.common.Authorization = newJwtTokens.accessToken;
              axios.defaults.headers.common.IdToken = newJwtTokens.idToken;

              originalRequest.headers['Authorization'] = newJwtTokens.accessToken;
              originalRequest.headers['IdToken'] = newJwtTokens.idToken;

              setJwtTokens(newJwtTokens);

              return axios(originalRequest);
            });
          } else {
            return Promise.reject(error);
          }
        } else {
          return error.response;
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <UserSelectorContext.Provider
      value={{
        user,
        isLoggedIn,
        isLoading,
        jwtTokens,
        clearJwtTokens
      }}
    >
      {children}
    </UserSelectorContext.Provider>
  );
};

export function useUserSelector() {
  const context = useContext(UserSelectorContext);

  if (!context) {
    throw new Error('useUserSelector must be used within a UserSelectorContextProvider');
  }

  return context;
}
