import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loadable from 'components/Loadable';
import { ProtectedRoute } from 'components/ProtectedRoute';
import Logout from 'components/SignOutButton';

const Home = Loadable(lazy(() => import('./pages/home/Home')));
const Users = Loadable(lazy(() => import('./pages/users/Users')));
const ReferralEarnings = Loadable(lazy(() => import('./pages/referrals/ReferralEarnings')));
const ReferralEarningsReviewRequests = Loadable(
  lazy(() => import('./pages/referrals/ReferralEarningsReviewRequests'))
);
const PassportRequests = Loadable(lazy(() => import('./pages/passport/PassportRequests')));
const PassportOnboardings = Loadable(
  lazy(() => import('./pages/passportOnboarding/PassportOnboardings'))
);
const PartnerCompanies = Loadable(lazy(() => import('./pages/partner/PartnerCompanies')));
const PartnerCompanyClients = Loadable(lazy(() => import('./pages/partner/PartnerCompanyClients')));
const PartnerClientManagement = Loadable(
  lazy(() => import('./pages/partner/PartnerClientManagement'))
);
const Login = Loadable(lazy(() => import('./pages/account/Login')));
const PasswordRecover = Loadable(lazy(() => import('./pages/account/PasswordRecover')));
const PasswordRecoverConfirm = Loadable(
  lazy(() => import('./pages/account/PasswordRecoverConfirm'))
);
const NotFound = Loadable(lazy(() => import('./pages/common/NotFound')));

const RoutesComponent = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals/earnings"
        element={
          <ProtectedRoute>
            <ReferralEarnings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals/earnings/review-requests"
        element={
          <ProtectedRoute>
            <ReferralEarningsReviewRequests />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport-requests"
        element={
          <ProtectedRoute>
            <PassportRequests />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport-onboardings"
        element={
          <ProtectedRoute>
            <PassportOnboardings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/partner-companies"
        element={
          <ProtectedRoute>
            <PartnerCompanies />
          </ProtectedRoute>
        }
      />
      <Route
        path="/partner-companies/:companyId"
        element={
          <ProtectedRoute>
            <PartnerCompanyClients />
          </ProtectedRoute>
        }
      />
      <Route
        path="/partner-companies/:companyId/:email"
        element={
          <ProtectedRoute>
            <PartnerClientManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport-requests/:email"
        element={
          <ProtectedRoute>
            <PassportRequests />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport-onboardings/:email"
        element={
          <ProtectedRoute>
            <PassportOnboardings />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/login/:email" element={<Login />} />
      <Route path="/password/recover" element={<PasswordRecover />} />
      <Route path="/password/recover/confirm/:email" element={<PasswordRecoverConfirm />} />
      <Route
        path="/logout"
        element={
          <ProtectedRoute>
            <Logout />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RoutesComponent;
